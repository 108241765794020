import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, Observable } from 'rxjs';
import { environment } from "../../environments/environment";
import { MindElixirData } from "mind-elixir/dist/types/types";
import { Operation } from "mind-elixir/dist/types/utils/pubsub";


type CircularReference = {
  id: string;
  topic: string;
};
export interface Mindmap {
  mindmap: {
    uuid: string;
  };
}

export interface MindmapBase {
  uuid: string;
  title: string;
  updatedAt: Date;
}

@Injectable({
  providedIn: 'root'
})
export class MindmapService {
  private apiUrl = `${environment.apiUrl}/mindmaps`;

  constructor(private http: HttpClient) { }

  async getMindmaps(): Promise<MindmapBase[]> {
    const mindmaps = await lastValueFrom(this.http.get<MindmapBase[]>(this.apiUrl));
    return mindmaps.map(mindmap => ({
      ...mindmap,
      updatedAt: new Date(mindmap.updatedAt)
    }));
  }

  async getMindmap(id: string): Promise<MindElixirData> {
    return await lastValueFrom(this.http.get<MindElixirData>(`${this.apiUrl}/${id}`));
  }

  async createMindmap(mindmap: MindElixirData): Promise<Mindmap> {
    return await lastValueFrom(this.http.post<Mindmap>(this.apiUrl, mindmap));
  }

  async updateMindmap(id: string, mindmap: Operation): Promise<any> {
    const serializedData = this.serializeData(mindmap);
    console.warn(mindmap)
    console.warn(serializedData)

    return await lastValueFrom(this.http.put<any>(`${this.apiUrl}/${id}`, serializedData));
  }

  deleteMindmap(id: number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${id}`);
  }

  private serializeData(data: Operation): any {
    const seen = new WeakSet();

    function replacer(key: any, value: any) {
      if (typeof value === 'object' && value !== null) {
        if (seen.has(value)) {
          const circularObj: CircularReference = { id: 'Circular', topic: 'Circular' };
          return circularObj;
        }
        seen.add(value);
      }
      return value;
    }

    return JSON.stringify(data, replacer);
  }
}
