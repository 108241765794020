<div class="container padding-bottom-large">
    <section class="paper article border shadow shadow-large">
        <h1>
            <a>Your Mindmaps ({{ mindmaps.length }})</a>
        </h1>

        <div class="row flex-spaces card-list">
            <div *ngFor="let mindmap of mindmaps" class="sm-12 md-5 col paper border shadow shadow-large shadow-hover">
                <img src="https://bejamas.io/_next/image/?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Flxs6x7jp%2Fproduction%2Ff31810d13e2e27f7f502218b8f4f28e0008e1ed7-3000x3000.png&w=640&q=75" alt="">
                <h2>
                    <a [routerLink]="['/mindmap', mindmap.uuid]">{{ mindmap.title }}</a>
                </h2>
                <p>Last updated: {{ mindmap.updatedAt | date: 'dd/MM/yyyy HH:mm:ss' }}</p>
            </div>
            <div class="sm-12 md-5 col paper border shadow shadow-large shadow-hover new-card">
                <a [routerLink]="['/mindmap']">
                    <div class="create-button">
                        <svg class="svg" title="new 40" aria-label="new 40" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 40 40">
                            <path fill="#ffffff" fill-opacity=".9" fill-rule="evenodd" stroke="none" d="M20.5 13.5h-1v6h-6v1h6v6h1v-6h6v-1h-6v-6z"></path>
                        </svg>
                    </div>
                </a>
            </div>
        </div>
    </section>
</div>
