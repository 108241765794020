<nav class="border split-nav">
  <div class="nav-brand">
    <img src="assets/Logo.png" alt="Logo" class="logo">
    <a href="#" class="brand-link">
      <h3>LoveToDoIt</h3>
    </a>
  </div>
  <div class="collapsible">
    <input id="collapsible1" type="checkbox" name="collapsible1">
    <label for="collapsible1">
      <div class="bar1"></div>
      <div class="bar2"></div>
      <div class="bar3"></div>
    </label>
    <div class="collapsible-body">
      <ul class="inline">
        <!-- Mobile Links -->
        <li><a href="/mindmaps">Mindmap List</a></li>
        <li><a href="/todo">ToDo</a></li>

        <!-- Desktop Links -->
        <li class="desktop-only">
          <div class="row flex-spaces child-borders">
          <label class="paper-btn btn-small" for="modal-1">Login</label>
        </div>
          <input class="modal-state" id="modal-1" type="checkbox">
          <div class="modal">
            <label class="modal-bg" for="modal-1"></label>
            <div class="modal-body">
              <label class="btn-close" for="modal-1">X</label>
              <h4 class="modal-title">login</h4>
              <div class="card-body">
                <div class="form-group">
                  <input class="input-block" type="text" placeholder="Username" id="paperInputs1" minlength="2">
                </div>
                <div class="form-group">
                  <input class="input-block" type="password" placeholder="Password" id="paperInputs2" minlength="4">
                </div>
                <button class="btn-block background-secondary">Sign In</button>
              </div>
            </div>
          </div>
        </li>
        <li class="desktop-only">
          <div class="row flex-spaces child-borders">
            <label class="paper-btn btn-small" for="modal-2">Register</label>
          </div>
          <input class="modal-state" id="modal-2" type="checkbox">
          <div class="modal">
            <label class="modal-bg" for="modal-2"></label>
            <div class="modal-body">
              <label class="btn-close" for="modal-2">X</label>
              <h4 class="modal-title">Register</h4>
              <div class="card-body">
                <div class="form-group">
                  <input class="input-block" type="text" placeholder="Username" id="paperInputs3" minlength="2">
                </div>
                <div class="form-group">
                  <input class="input-block" type="password" placeholder="Password" id="paperInputs4" minlength="4">
                </div>
                <div class="form-group">
                  <input class="input-block" type="password" placeholder="Repeat Password" id="paperInputs5" minlength="4">
                </div>
                <button class="btn-block background-secondary">Sign Up</button>
              </div>
            </div>
          </div>
        </li>
        <li class="mobile-only"><a href="/login">Login</a></li>
        <li class="mobile-only"><a href="/register">Register</a></li>
      </ul>
    </div>
  </div>
</nav>

<!--<nav class="navbar navbar-light">-->
<!--  <div class="container">-->
<!--    <a class="navbar-brand" routerLink="/">conduit</a>-->

<!--    &lt;!&ndash; Show this for logged out users &ndash;&gt;-->
<!--    <ul *ifAuthenticated="false" class="nav navbar-nav pull-xs-right">-->
<!--      <li class="nav-item">-->
<!--        <a class="nav-link" routerLink="/"> Home </a>-->
<!--      </li>-->

<!--      <li class="nav-item">-->
<!--        <a class="nav-link" routerLink="/login" routerLinkActive="active">-->
<!--          Sign in-->
<!--        </a>-->
<!--      </li>-->

<!--      <li class="nav-item">-->
<!--        <a class="nav-link" routerLink="/register" routerLinkActive="active">-->
<!--          Sign up-->
<!--        </a>-->
<!--      </li>-->
<!--    </ul>-->

<!--    &lt;!&ndash; Show this for logged in users &ndash;&gt;-->
<!--    <ul *ifAuthenticated="true" class="nav navbar-nav pull-xs-right">-->
<!--      <li class="nav-item">-->
<!--        <a-->
<!--          class="nav-link"-->
<!--          routerLink="/"-->
<!--          routerLinkActive="active"-->
<!--          [routerLinkActiveOptions]="{ exact: true }"-->
<!--        >-->
<!--          Home-->
<!--        </a>-->
<!--      </li>-->

<!--      <li class="nav-item">-->
<!--        <a class="nav-link" routerLink="/editor" routerLinkActive="active">-->
<!--          <i class="ion-compose"></i>&nbsp;New Article-->
<!--        </a>-->
<!--      </li>-->

<!--      <li class="nav-item">-->
<!--        <a class="nav-link" routerLink="/settings" routerLinkActive="active">-->
<!--          <i class="ion-gear-a"></i>&nbsp;Settings-->
<!--        </a>-->
<!--      </li>-->

<!--      @if (currentUser$ | async; as currentUser) {-->
<!--        <li class="nav-item">-->
<!--          <a-->
<!--            class="nav-link"-->
<!--            [routerLink]="['/profile', currentUser.username]"-->
<!--            routerLinkActive="active"-->
<!--          >-->
<!--            <img-->
<!--              [src]="currentUser.image"-->
<!--              *ngIf="currentUser.image"-->
<!--              class="user-pic"-->
<!--            />-->
<!--            {{ currentUser.username }}-->
<!--          </a>-->
<!--        </li>-->
<!--      }-->
<!--    </ul>-->
<!--  </div>-->
<!--</nav>-->
