import { Component, OnInit } from '@angular/core';
import { MindmapBase, MindmapService } from "../../../../services/mindmap.service";
import { CommonModule } from "@angular/common";
import { Router, RouterModule } from "@angular/router";

@Component({
  selector: 'app-list',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './list.component.html',
  styleUrl: './list.component.scss'
})
export class ListComponent implements OnInit {
  mindmaps: MindmapBase[] = [];

  constructor(
      private readonly router: Router,
      private mindmapService: MindmapService
  ) { }

  async ngOnInit(): Promise<void> {
    try {
      this.mindmaps = await this.mindmapService.getMindmaps();
    } catch (error) {
      console.error('Error fetching mindmaps:', error);
    }
  }
}
