import {Component, ElementRef, Inject, PLATFORM_ID, ViewChild} from '@angular/core';
import {MindmapService} from "../../../../services/mindmap.service";
import {isPlatformBrowser} from "@angular/common";
import MindElixir from "mind-elixir";
import {HttpClientModule} from "@angular/common/http";
import {MindElixirData} from "mind-elixir/dist/types/types";
import {Operation} from "mind-elixir/dist/types/utils/pubsub";
import {Router} from "@angular/router";

@Component({
  selector: 'app-create',
  standalone: true,
  imports: [HttpClientModule],
  templateUrl: './create.component.html',
  styleUrl: './create.component.scss'
})
export class CreateComponent {
  @ViewChild('map', { static: true }) mapContainer!: ElementRef;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private mindMapService: MindmapService,
    private router: Router,
  ) { }

  async ngAfterViewInit(): Promise<void> {
    if (isPlatformBrowser(this.platformId)) {
      const mind = new MindElixir({
        el: this.mapContainer.nativeElement,
        direction: MindElixir.SIDE,
        draggable: true,
        contextMenu: true,
        toolBar: true,
        nodeMenu: true,
        keypress: true,
        mobileMenu: true,
        locale: 'de',
        overflowHidden: false,
        mouseSelectionButton: 0,
        contextMenuOption: {
          focus: true,
          link: true,
          extend: [
            {
              name: 'Node edit',
              onclick: () => {
                alert('this is just a test!!')
              },
            },
          ],
        },
        before: {
          insertSibling(el, obj) {
            return true
          },
          async addChild(el, obj) {
            return true
          },
        },
      });

      const data = MindElixir.new('LoveToDoIt')

      try {
        const mindmap = await this.mindMapService.createMindmap(data);
        console.log('RESPONSE ', mindmap?.mindmap.uuid);
        // Navigate to the editor component
        await this.router.navigate(['/mindmap', mindmap?.mindmap.uuid]);
      } catch (error) {
        console.error('Error posting mind map data:', error);
      }
    }
  }
}
