import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import {HttpClientModule} from "@angular/common/http";
import {HeaderComponent} from "./core/layout/header.component";
import {FooterComponent} from "./core/layout/footer.component";
import {environment} from "../environments/environment";

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: "./app.component.html",
  styleUrl: "./app.component.scss",
  imports: [
    HeaderComponent,
    RouterOutlet,
    HttpClientModule,
    FooterComponent
  ],
})
export class AppComponent {
  constructor() {
  }
}
