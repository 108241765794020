import { Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { MindmapService } from "../../../../services/mindmap.service";
import { isPlatformBrowser } from "@angular/common";
import MindElixir from "mind-elixir";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'app-editor',
  standalone: true,
  templateUrl: './editor.component.html',
  styleUrl: './editor.component.scss'
})
export class EditorComponent implements OnInit {
  mindmapData: any;
  mindmapUuid: any;

  @ViewChild('map', { static: true }) mapContainer!: ElementRef;

  constructor(
      @Inject(PLATFORM_ID) private platformId: Object,
      private mindMapService: MindmapService,
      private readonly router: Router,
      private readonly route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.mindmapUuid = this.route.snapshot.params["slug"];
  }

  async ngAfterViewInit(): Promise<void> {
    if (isPlatformBrowser(this.platformId)) {
      try {
        this.mindmapData = await this.mindMapService.getMindmap(this.mindmapUuid);
      } catch (error) {
        console.error('Error fetching mind map data:', error);
      }

      const mind = new MindElixir({
        el: this.mapContainer.nativeElement,
        direction: MindElixir.SIDE,
        draggable: true,
        contextMenu: true,
        toolBar: true,
        nodeMenu: true,
        keypress: true,
        mobileMenu: true,
        locale: 'de',
        overflowHidden: false,
        mouseSelectionButton: 0,
        contextMenuOption: {
          focus: true,
          link: true,
          extend: [
            {
              name: 'Node edit',
              onclick: () => {
                alert('this is just a test!!')
              },
            },
          ],
        },
        before: {
          insertSibling(el, obj) {
            return true
          },
          async addChild(el, obj) {
            return true
          },
        },
      });

      mind.init(this.mindmapData);

      mind.bus.addListener('operation', operation => {
        try {
          console.info('operation type:', operation);
          console.info('info',this.mindMapService.updateMindmap(this.mindmapUuid, operation));
        } catch (error) {
          console.error('Error updating mind map data:', error);
        }
      });
    }
  }
}
