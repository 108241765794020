<div class="container auth">
<form class="auth-card paper shadow shadow-large shadow-hover">
    <div class="card-header">Sign In</div>
    <div class="card-body">
        <div class="form-group">
            <input class="input-block" type="text" placeholder="Username" id="paperInputs1" minlength="2">
        </div>
        <div class="form-group">
            <input class="input-block" type="password" placeholder="Password" id="paperInputs2" minlength="4">
        </div>
        <button class="btn-block background-secondary">Sign In</button>
    </div>
</form>
</div>