import { Routes } from '@angular/router';
import {ListComponent} from "./features/mindmap/pages/list/list.component";
import {EditorComponent} from "./features/mindmap/pages/editor/editor.component";
import {CreateComponent} from "./features/mindmap/pages/create/create.component";
import {HomeComponent} from "./features/home/pages/home/home.component";
import {AuthComponent} from "./core/auth/auth.component";
import {Todo_listComponent} from "./features/todo/pages/list/todo_list.component";

export const routes: Routes = [
    { path: '', component: HomeComponent },
    { path: 'login', component: AuthComponent },
    { path: 'register', component: AuthComponent },
    { path: 'mindmaps', component: ListComponent },
    { path: 'mindmap', children: [
      {
        path: "",
        component: CreateComponent
      },
      {
        path: ":slug",
        component: EditorComponent
      },
    ],
    },
    { path: 'todo', component: Todo_listComponent },
];
