<div class="container list">
    <div class="paper border shadow shadow-large shadow-hover">
        <form>
            <div>
                <label for="input">
                    <input id="input" type="checkbox">
                    Target label as the previous sibling
                </label>
            </div>
            <div>
                <label for="input1">
                    <input id="input1" type="checkbox">
                    Target label as the parent element
                </label>
            </div>
            <div>
                <label for="input2">
                    <input id="input2" type="checkbox">
                    Target label as the parent element
                </label>
            </div>
            <div>
                <label for="input3">
                    <input id="input3" type="checkbox">
                    Target label as the parent element
                </label>
            </div>
            <div>
                <label for="input4">
                    <input id="input4" type="checkbox">
                    Target label as the parent element
                </label>
            </div>
        </form>
    </div>
</div>