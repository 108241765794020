<div class="container padding-bottom-large">
    <section class="paper border">
        <h1>Love to do it</h1>
        <p>lovetodo.t is your go-to platform for effortless self-organization.
        </p>
        <p>Imagine a seamlessly integrated system where tasks are effortlessly managed, tagged, and prioritized, all
            while offering a delightful user experience. </p>
        <p>Our platform redefines task management by providing a dynamic database where every task is intelligently
            tagged, allowing for easy sorting and filtering. Whether you're at the grocery store or brainstorming at
            home, lovetodo.it ensures that no task slips through the cracks.</p>
        <p>Our platform isn't just about task management; it's about enhancing productivity and reclaiming control over
            your time.</p>
        <p> With features like intuitive data entry options, auto-completion, and customizable widgets, lovetodo.it
            prioritizes simplicity and efficiency.</p>
        <p> Plus, with a robust backend infrastructure, tasks are securely stored and readily accessible whenever and
            wherever you need them. </p>
        <p>React frameworks like Next.js and Gatsby have been around for 5 years. Companies using them from the start
            have run into issues with how their code is built, growing their platforms, and dealing with tech debt.
            We're here to spot those big problems and develop clear, doable solutions.</p>
        <p>Whether you're a busy professional, a student juggling multiple responsibilities, or simply someone looking
            to optimize their daily routines, lovetodo.it is here to revolutionize the way you organize your life. Say
            goodbye to scattered to-do lists and hello to a more streamlined, enjoyable approach to self-management.
            Welcome to lovetodo.it, where self-organization becomes a pleasure.</p>
    </section>
</div>